<!-- Finder particular de Funerarias Centrales -->

<template>
  <div class="funecF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>
      
        <!-- Contenido -->
        <div class="contenedor"> 

          <!-- <div v-html="info"></div> -->
         
          <div style="display:flex;align-items:center">
            <div v-if="tipoVista" class="columna" style="width:40rem">
              <div class="conflex">        
         
                <uiText                 
                  v-model="ct.name[2]"
                  :label="ct.name[1]"
                  @Enter="eventFiltro('buscar')">                
                </uiText>              

                <v-btn
                  v-bind="$cfe.btn.busca"
                  @click="eventFiltro('buscar')">              
                  <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
                </v-btn>

                <v-checkbox
                  class="ml-2"
                  style="width:30%"
                  v-bind="$checkbox"
                  v-model="ct.bajas[2]"
                  :label="ct.bajas[1]">
                </v-checkbox>

              </div>

              <div class="conflex">
                <ctrlF style="width:50%" :ct="ct.loc"></ctrlF>                
              </div>              

            </div>
          </div>       
                    

          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="25"           
            dense
            @onEvent="execAccion">
         
              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>
                    
              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
          </baseGridMD>          
        </div>
      </v-sheet>

      <!-- Mto Funerarias Centrales  -->      
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component> 

    </div>
  </div>
</template>



<script>
  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto"; 
  import ctrlF from "@/components/ctrlF";
  import funecM from "@/components/funecM";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, ctrlF, funecM },
    props: {},

    data() {
      return {
        stIni: {
          api: "funecF",
          titulo:"Funerarias Centrales", 
          name:"funecF",
          mView:'funecM',
          pView:[]
        },
        
      };
    },

    computed: {
      tipoVista() {
        if (this.tipoView!= 'FA' && this.tipoView!= 'FL') return true;
        return false;
      }
    }, 

    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[        
            { text: "Acciones", value: "acciones", sortable: false, width: "15%", slot:true },
            { text: "Funeraria", value: "name", width: "40%" },
            { text: "Situación", value: "situ", width: "5%" },
            { text: "Atlas", value: "origen", width: "40%" }
          ]}
        ];

        this.headerGrid= this.headerSet();
        
      },

    }
  };
</script>
